// @flow

import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userIdActions from '../../../actions/userIdActions';
import Rainbow from '../../shared/rainbow/Rainbow';
import './FormMP.scss';
import type { PageProps } from '../../../lib/types';

const FormMP: React.FC = ({ setUserId }: PageProps) => {
  const inputRef = useRef();
  const navigate = useNavigate();

  const submitHandler = () => {
    const { value } = inputRef.current;

    if (value) {
      setUserId(value);
      navigate('/en/splash');
    }
  };

  return (
    <>
      <Rainbow />
      <form className="FormMP">
        <label htmlFor="id">Enter your ID below:</label>
        <input ref={inputRef} id="id" type="text" required />

        <div className="nav-buttons-container">
          <button type="button" className="nav-button" onClick={submitHandler}>
            Proceed to MyPath
          </button>
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = userIdActions;

export default connect(null, mapDispatchToProps)(FormMP);
